<template>
  <div>
    <v-dialog v-model="dialog" eager width="500" persistent>
      <v-btn color="error" block @click="paymentCancel">
        <v-icon left>{{ icons.mdiClose }}</v-icon>
        İşlemi İptal Et
      </v-btn>
      <div id="credit"></div>
    </v-dialog>

    <v-row>
      <v-col v-if="status" class="d-block d-md-flex justify-center pt-2 pb-6 py-md-16">
        <v-progress-circular v-if="pageLoading" class="el-center" :size="120" indeterminate>
          <span class="text-caption">Yükleniyor</span>
        </v-progress-circular>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card v-if="!pageLoading" elevation="16" :width="$vuetify.breakpoint.mdAndUp ? 900 : '100%'">
            <v-card-title class="black">
              <v-icon color="white" :size="$vuetify.breakpoint.mdAndUp ? '50' : '30'" left>
                {{ icons.mdiCreditCard }}
              </v-icon>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h6 white--text' : 'text-body-1'">Online Ödeme</span>
              <v-spacer />
            </v-card-title>
            <customer-list :styleTheme="true" v-if="userDataRole.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

            <v-divider />
            <v-container class="pa-6">
              <div v-if="paymentError" class="px-3 pt-3">
                <v-alert prominent type="error">
                  <div>Ödeme Başarısız</div>
                  <div class="text-caption">
                    {{ paymentErrorMsg }}
                  </div>
                </v-alert>
              </div>
              <CardForm
                :form-data="formData"
                @input-card-number="updateCardNumber"
                @input-card-name="updateCardName"
                @input-card-month="updateCardMonth"
                @input-card-year="updateCardYear"
                @input-card-cvv="updateCardCvv"
              />
              <v-row>
                <v-col cols="12" md="5"></v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="manuelTutar"
                    hide-details
                    :rules="[v => !!v || 'Item is required']"
                    label="Ödenecek Tutar"
                    filled
                    rounded
                    class="rounded"
                    suffix="TL"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="taksitlendirme.installment">
                <v-col cols="12">
                  <v-item-group v-model="taksitSecim" mandatory>
                    <v-row dense>
                      <v-col v-for="taksit in taksitlendirme.installment" :key="taksit.id" cols="6" md="2">
                        <v-item v-slot="{ active, toggle }">
                          <v-card
                            :color="active ? 'secondary' : 'nightDark'"
                            class="d-flex align-center justify-center"
                            dark
                            height="75"
                            @click="toggle"
                          >
                            <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : ''">
                              <span v-if="taksit.installment_text === 'Tek Çekim'" class="text-center">
                                {{ taksit.installment_text }}
                              </span>
                              <span v-else> {{ taksit.installment_text.charAt(0) }} Taksit </span>
                            </div>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="5"></v-col>
                <v-col cols="12" md="7">
                  <!-- 
                  <v-card class="rounded-lg" flat color="nightDark">
                    <div class="d-flex align-center justify-space-between">
                      
                      <v-card-title>
                        <div class="text-caption text-md-body-1 font-weight-medium">Ödenecek Tutar</div>
                        <div v-if="taksitSayisi != 'T' && taksitSayisi != 0" class="text-caption">
                          ( {{ taksitTutar | currency }} x {{ taksitSayisi }} )
                        </div>
                      </v-card-title>

                      <v-card-title class="justify-end">
                        <v-chip color="secondary" label :large="$vuetify.breakpoint.mdAndUp">
                          {{ toplamOdenecekTutar | currency }}
                        </v-chip>
                      </v-card-title>
                    </div>
                  </v-card>
                -->
                  <v-btn color="tertiary white--text" x-large tile block class="mt-6" @click="submit">
                    Ödeme Yap
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" v-else class="mt-3">
        <customer-list v-if="userDataRole.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

        <v-alert tile type="warning"> Lütfen yukarıdan bir müşteri seçin </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardForm from '@/components/CardForm.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import {
  mdiAccountCircle,
  mdiAt,
  mdiCalendar,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiLock,
  mdiPhone,
  mdiUpdate,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'

export default {
  components: {
    CardForm,
    CustomerList,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    const odemeTipi = ref(0)
    const { uid } = JSON.parse(localStorage.getItem('userData'))
    const kredikartino = ref('')
    const kredikartiadsoyad = ref('')
    const kredikartiinstallment = ref([])
    const kredikartiay = ref('')
    const kredikartiyil = ref('')
    const kredikarticvv = ref('')

    const toplamOdenecekTutar = ref(0)
    const valid = ref(true)
    const kkAdSoyad = ref(null)
    const kkAy = ref(null)
    const kkYil = ref(null)
    const kkCvv = ref(null)
    const kkNo = ref(null)
    const selectedInstallment = ref(null)
    const bankaKodu = ref(null)
    const cardFamily = ref(null)
    const cardBankName = ref(null)

    const checkbox1 = ref(false)
    const checkbox2 = ref(false)
    const checkbox3 = ref(false)
    const taksitlendirme = ref({})
    const taksitSecim = ref(null)
    const taksitTutar = ref(0)
    const taksitSayisi = ref(0)
    const dialog = ref(false)
    const form = ref(null)

    const { router } = useRouter()
    const userData = ref({})
    const loading = ref(true)
    const userDataRole = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const pageLoading = ref(true)
    const desserts = ref([])
    onMounted(() => {
      if (userDataRole.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        fetchBalance()
      }
    })
    watch(
      () => store.state.plasiyerCustomer,
      () => {
        pageLoading.value = true
        if (store.state.plasiyerCustomer) {
          status.value = true
          fetchBalance()
        }
      },
    )
    const fetchBalance = () => {
      const method = 'getCustomerBalanceDetailed'
      let customer
      if (router.currentRoute.params.id) {
        customer = router.currentRoute.params.id
      } else {
        customer = uid
      }

      store
        .dispatch('postMethod', { method, customer })
        .then(response => {
          if (response.error === 0) {
            userData.value = response.balance[0]
            toplamOdenecekTutar.value =
              -1 * (Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL))
            desserts.value = [
              {
                name: 'Bakiye',
                tutar: response.balanceDetail.head[0].TBLVAL,
              },
              {
                name: 'Faturalanmamış Sipariş',
                tutar: response.balanceDetail.head[1].TBLVAL,
              },
              {
                name: 'Kendi Çeki/Senedi',
                tutar: response.balanceDetail.head[3].TBLVAL,
              },
              {
                name: 'Müşteri Çeki/Senedi',
                tutar: response.balanceDetail.head[10].TBLVAL,
              },
              {
                name: 'Toplam Risk',
                tutar: response.balanceDetail.head[15].TBLVAL,
              },

              {
                name: 'Risk Limiti',
                tutar: response.balanceDetail.head[6].TBLVAL,
              },
              {
                name: 'Kalan Açık Tutar',
                tutar: Number(response.balanceDetail.head[6].TBLVAL) - Number(response.balanceDetail.head[15].TBLVAL),
              },
            ]

            pageLoading.value = false
          }
        })
        .catch(error => {
          if (error.response?.status === 404) {
            userData.value = {}
          }
        })
    }
    const sleep = ms =>
      new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    const whileStop = ref(false)

    const orderStatus = ref()
    const paymentErrorMsg = ref(null)
    const paymentError = ref(false)
    var iframe
    const submit = async () => {
      const payload = {
        method: 'addPayment',
        tutar: toplamOdenecekTutar.value,
        paymentType: 1,
        kk_no: kredikartino.value.replaceAll(' ', '') || '4506347048543223',
        kk_expire: kredikartiay.value + '/' + kredikartiyil.value.slice(-2) || '12/30',
        kk_adsoyad: kredikartiadsoyad.value || 'mehmet tali',
        kk_cvv: kredikarticvv.value || '000',
        selectedInstallment: kredikartiinstallment.value?.[taksitSecim.value]?.id || '130',
        bankaKodu: bankaKodu.value || '0015',
        cardFamily: cardFamily.value || 'World',
        cardBankName: cardBankName.value || 'Vakıfbank',
        paymentMethod: 2,
        paymentDiffMode: 1,
        cardType: 1,
        calcedPrice: '',
      }

      if (!form.value.validate()) {
        return
      }
      paymentErrorMsg.value = ''
      paymentError.value = false

      loading.value = true

      postData(payload).then(async response => {
        if (response.error === false) {
          dialog.value = true
          iframe = document.createElement('iframe')
          iframe.width = '500'
          iframe.height = '600'
          iframe.srcdoc = response.html

          document.getElementById('credit').appendChild(iframe)
          // const { refNo } = response
          // const method = 'paymentCheck'
          // const veri = {
          //   method,
          //   refNo,
          // }
          // while (true) {
          //   postData(veri).then(result => {
          //     if (result.error === 0) {
          //       // BAŞARALI SAYFASI
          //       orderStatus.value = 0
          //       whileStop.value = true
          //     } else if (result.error === 1) {
          //       // BAŞARISIZ SAYFASI
          //       whileStop.value = true
          //       paymentErrorMsg.value = result.msg
          //       orderStatus.value = 1
          //     }
          //   })

          //   if (whileStop.value) {
          //     if (orderStatus.value === 0) {
          //       Vue.swal({ icon: 'success', text: 'Ödemeniz Alındı' })
          //       router.push({ name: 'credit-card-transactions' })
          //     } else if (orderStatus.value === 1) {
          //       paymentError.value = true
          //       dialog.value = false
          //     }

          //     break
          //   }
          //   // eslint-disable-next-line no-await-in-loop
          //   await sleep(500000000000000)
          // }
          // iframe.remove()
          // whileStop.value = false
          // orderStatus.value = null
        } else {
          paymentErrorMsg.value = response.msg
          paymentError.value = true
          Vue.swal({
            title: 'Hata',
            html: response.msg,
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: false,
          })
        }
      })
    }
    const paymentCancel = () => {
      orderStatus.value = null
      paymentErrorMsg.value = null
      paymentError.value = false
      loading.value = false
      iframe.remove()
      whileStop.value = true
      dialog.value = false
    }
    return {
      paymentCancel,
      paymentErrorMsg,
      paymentError,
      submit,
      form,
      dialog,
      checkbox1,
      checkbox2,
      checkbox3,
      toplamOdenecekTutar,
      taksitlendirme,
      taksitSecim,
      valid,
      kkAdSoyad,
      kkAy,
      kkYil,
      kkCvv,
      kkNo,
      kredikartino,
      kredikartiadsoyad,
      kredikartiay,
      kredikartiyil,
      kredikarticvv,
      kredikartiinstallment,
      selectedInstallment,
      bankaKodu,
      cardFamily,
      cardBankName,
      taksitTutar,
      taksitSayisi,
      menuIsVerticalNavMini,
      userData,
      userDataRole,
      status,
      loading,
      pageLoading,
      desserts,
      odemeTipi,
      icons: {
        mdiContactlessPayment,
        mdiAccountCircle,
        mdiLock,
        mdiPhone,
        mdiAt,
        mdiCalendar,
        mdiCreditCard,
        mdiUpdate,
      },
    }
  },
  data() {
    return {
      manuelTutar: null,
      headers: [
        {
          text: 'Ad',
          align: 'right',
          sortable: false,
          value: 'name',
        },

        {
          text: 'Değer',
          sortable: false,
          align: 'right',
          value: 'tutar',
        },
      ],

      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    }
  },

  watch: {
    manuelTutar(val) {
      this.toplamOdenecekTutar = val
    },
    taksitSecim(val) {
      this.toplamOdenecekTutar = this.taksitlendirme.installment[val].inst_tot_amount

      this.taksitTutar = this.taksitlendirme.installment[val].inst_amount
      this.taksitSayisi = this.taksitlendirme.installment[val].installment_text.charAt(0)
    },
  },
  methods: {
    updateCardNumber(val) {
      if (val.length > 18) {
        const payload = {
          method: 'getInstallmentsByBin',
          kk_no: val.replaceAll(' ', ''),
          price: this.toplamOdenecekTutar,
        }
        this.$store.dispatch('app-order/fetchBinList', payload).then(response => {
          this.taksitlendirme = response
          this.cardBankName = response.card_bank_name
          this.cardFamily = response.card_family
          this.bankaKodu = response.bank_code
          this.kredikartiinstallment = response.installment
        })

        this.kkNo = val
        this.kredikartino = val
      } else {
        this.taksitlendirme = {}
      }
    },
    updateCardName(val) {
      this.kkAdSoyad = val
      this.kredikartiadsoyad = val
    },
    updateCardMonth(val) {
      this.kkAy = val
      this.kredikartiay = val
    },
    updateCardYear(val) {
      this.kkYil = val
      this.kredikartiyil = val
    },
    updateCardCvv(val) {
      this.kkCvv = val
      this.kredikarticvv = val
    },
  },
}
</script>
